import { useState, useEffect } from "react";
import style from "./Enquiry.module.css";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import SteinStore from "stein-js-client";
import EnquiryForm from "components/EnquireForm/EnquireForm";
function Enquiry() {
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleFormSubmissionSuccess = () => {
    console.log("submitted");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFullNameValid && isEmailValid && isPhoneValid) {
      const SteinStore = require("stein-js-client");
      const store = new SteinStore(
        "https://api.steinhq.com/v1/storages/65a51e952acf6d63de7b880f"
      );

      const formData = {
        Name: fullName,
        Email: emailId,
        Phone: phone,
        selectedOption: selectedOption,
        timestamp: new Date().toLocaleString(),
      };

      // Append data to different sheets based on the selected option
      switch (selectedOption) {
        case "General":
          store.append("General", [formData]);
          break;
        case "MAJIK":
          store.append("Majik", [formData]);
          break;
        case "Corporate training":
          store.append("CorporateTraining", [formData]);
          break;
        case "Offline training":
          store.append("OfflineTraining", [formData]);
          break;
        default:
          console.error("Invalid selected option");
      }

      // Optional: Reset form fields after successful submission
      setFullName("");
      setEmailId("");
      setPhone("");
      setSelectedOption("");
      setIsFormSubmitted(true);
    } else {
      // Handle invalid form submission
      console.error(
        "Form validation failed. Please fill in all the required fields correctly."
      );
    }
  };

  // Email Validation
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailId(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
  };

  // Full name validation
  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);

    setIsFullNameValid(fullName.trim() !== "");
  };

  // Phone validation
  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setPhone(newPhone);

    const phoneRegex = /^\d{10}$/;
    setIsPhoneValid(phoneRegex.test(newPhone));
  };

  // Select field change
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className={style.wrapper}>
      <EnquiryForm onSuccess={handleFormSubmissionSuccess} />
    </div>
  );
}

export default Enquiry;
