export const routes = {
  Home: "/",
  About: "/about",
  Projects: "/projects",
  Contact: "/contact",
  LnD: "/lnd",
  Blogs: "/blogs",
  Career: "/career",
  Services: "/services",
  Enquiry: "/Enquiry",
  Majik: "/Majik",
  Majik_: "/Majik_",
};
