import React, { useState, useEffect } from "react";

import Background from "components/Home/Background/Background";
import Logos from "components/Home/Logos/Logos";
import Projects from "components/Home/Projects/Projects";
import Services from "components/Home/Services/Services";
import OurTeam from "components/Home/OurTeam/OurTeam";
import Training from "components/Home/Training/Training";
import CloseIcon from "@mui/icons-material/Close";
import style from "./Home.module.css";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import EnquiryForm from "components/EnquireForm/EnquireForm";

const Home = () => {
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    setOpenPopup(true);
  }, []);

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const handleFormSubmissionSuccess = () => {
    setOpenPopup(false);
  };

  return (
    <>
      <Background />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <div
          style={{
            width: "85%",
          }}
        >
          <Logos />
          <Projects />
          <Services />
          <OurTeam />
          <Training />
          <Dialog open={openPopup} onClose={handleClosePopup}>
            <DialogActions>
              <Button size="small" color="error" onClick={handleClosePopup}>
                <CloseIcon />
              </Button>
            </DialogActions>
            <DialogTitle style={{ backgroundColor: "#FFE000", color: "black" }}>
              <Typography
                fontFamily="Inter"
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "26px",
                  },
                }}
                fontWeight="400"
              >
                Join Our <span style={{ fontWeight: "600" }}>MAJIK </span>
                Program,
              </Typography>
              <Typography
                fontFamily="Inter"
                marginBottom="15px"
                fontSize="14px"
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                    md: "16.5px",
                  },
                }}
                fontWeight="800"
                color="#575757"
              >
                MASTERING <span className={style.javaText}>JAVA</span>
                INTERVIEW KNACK
              </Typography>
              <Typography
                fontFamily="Inter"
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "19px",
                    md: "20px",
                  },
                }}
                fontWeight="700"
              >
                DEMO AT JUST
                <span
                  style={{
                    fontSize: "25px",
                    fontWeight: "800",
                    padding: "3px 6px 3px 6px",
                  }}
                >
                  ₹99
                </span>
              </Typography>
              <Typography fontFamily="Inter" fontSize="14px" fontWeight="400">
                Enter details to register
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
              <EnquiryForm
                height={"400px"}
                onSuccess={handleFormSubmissionSuccess}
              />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default Home;
