import React, { useState } from "react";

import ReactCardFlip from "react-card-flip";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Lms from "../../../assets/lms.png";
import Hrms from "../../../assets/hrms.png";
import Los from "../../../assets/los.png";
import Erp from "../../../assets/erp.png";
import Wms from "../../../assets/wms.png";
import WestIcon from "@mui/icons-material/West";

const predefinedColors = [
  "#FFFCE3",
  "#F1F1F1",

  // Add more predefined colors here as needed
];

const projectData = [
  {
    icon: Lms,
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    detailed_desc:
      "Our Learning Management System (LMS) is a comprehensive platform designed to revolutionise the educational experience. Tailored for seamless integration into academic institutions, our LMS offers a vast repository of courses and classes. Students can easily enrol, pay for courses, and embark on a personalised learning journey with a customised schedule. The system ensures an interactive and engaging learning environment, empowering students to acquire knowledge at their own pace.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Hrms,
    title: "HRMS - Human Resource Management System",
    description:
      "Our Human Resource Management System (HRMS) is a powerful solution",
    detailed_desc:
      "Our Human Resource Management System (HRMS) is a powerful solution designed to streamline and optimise HR operations within organisations. From employee onboarding to performance management, our HRMS offers a suite of tools that simplify administrative tasks, enhance communication, and provide valuable insights for informed decision-making. This comprehensive system is tailored to meet the diverse needs of modern workplaces, ensuring efficient and effective management of human resources.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Erp,
    title: "ERP Solutions",
    description:
      "Our Enterprise Resource Planning (ERP) solutions provide a holistic approach",
    detailed_desc:
      "Our Enterprise Resource Planning (ERP) solutions provide a holistic approach to business management. By integrating various processes and functions across an organisation, our ERP solutions enhance efficiency, reduce redundancy, and facilitate data-driven decision-making. From finance and accounting to supply chain and customer relationship management, our ERP solutions offer a unified platform that fosters collaboration and supports organisational growth.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Los,
    title: "Logistics Operation Software",
    description:
      "Our Logistics Operation Software is a cutting-edge solution designed to optimise",
    detailed_desc:
      "Our Logistics Operation Software is a cutting-edge solution designed to optimise and streamline logistics and courier operations. From real-time tracking to route optimization, this software empowers logistics companies to enhance efficiency and provide superior service. It encompasses features like order management, route planning, and data-driven insights, ensuring that logistics operations run seamlessly in the fast-paced industry.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Erp,
    title: "Accounting Software",
    description:
      "Our Accounting Software is a robust financial management solution",
    detailed_desc:
      "Our Accounting Software is a robust financial management solution that simplifies and automates accounting processes. From ledger management to financial reporting, this software offers a user-friendly interface for accurate and efficient financial tracking. Whether managing expenses, invoicing, or generating financial statements, our Accounting Software provides the tools needed to maintain fiscal transparency and make informed financial decisions.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Wms,
    title: "Warehouse Management Software",
    description:
      "Our Warehouse Management Software is designed to optimise and streamline warehouse operations. ",
    detailed_desc:
      "Our Warehouse Management Software is designed to optimise and streamline warehouse operations. From inventory tracking to order fulfilment, this software provides real-time visibility into warehouse activities. Features include inventory management, order processing, and supply chain optimization, ensuring efficient warehouse management and contributing to overall business success.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Erp,
    title: "School ERP",
    description:
      "Our School ERP is a comprehensive solution designed specifically for educational",
    detailed_desc:
      "Our School ERP is a comprehensive solution designed specifically for educational institutions. From student enrollment to grade management, this software streamlines administrative tasks, enhances communication, and facilitates collaboration between students, teachers, and parents. The School ERP ensures a smooth and efficient operation of educational institutions, promoting an environment conducive to learning.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Los,
    title: "Project Management Software",
    description:
      "Our Project Management Software is a robust tool for planning, organising",
    detailed_desc:
      "Our Project Management Software is a robust tool for planning, organising, and executing projects effectively. It offers features such as task management, collaboration tools, and real-time progress tracking. With intuitive interfaces and customizable workflows, our Project Management Software ensures teams can work seamlessly, promoting collaboration and successful project outcomes.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Wms,
    title: "mGit",
    description:
      "mGit is our custom GIT Version Control Tool, tailored to meet the unique needs of software development teams. ",
    detailed_desc:
      "mGit is our custom GIT Version Control Tool, tailored to meet the unique needs of software development teams. It provides a streamlined approach to version control, facilitating collaboration, code management, and project tracking. With features like branching, merging, and issue tracking, mGit ensures a smooth and efficient version control process for development projects.",
    backgroundColor: "#F0F7EF",
  },
  {
    icon: Wms,
    title: "Communication Platform similar to Mattermost & Zulip",
    description:
      "Our Communication Platform is a robust messaging and collaboration tool designed for teams.",
    detailed_desc:
      "Our Communication Platform is a robust messaging and collaboration tool designed for teams. With features similar to Mattermost and Zulip, our platform facilitates real-time communication, file sharing, and project collaboration. It provides a secure and organised space for team discussions, ensuring efficient communication and fostering a collaborative work environment.",
    backgroundColor: "#F0F7EF",
  },
];

const Projects = () => {
  const getColor = (index) => {
    return predefinedColors[index % predefinedColors.length];
  };
  const [isFlippedArray, setIsFlippedArray] = useState(
    new Array(projectData.length).fill(false)
  );

  const flipCard = (index) => {
    const updatedFlippedArray = [...isFlippedArray];
    updatedFlippedArray[index] = !updatedFlippedArray[index];
    setIsFlippedArray(updatedFlippedArray);
  };
  const getCardSize = (index) => {
    return isFlippedArray[index]
      ? { width: "100%", height: "347px" }
      : { maxWidth: "397px", height: "347px" };
  };
  return (
    <>
      <Typography
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="300"
        textTransform="uppercase"
        borderBottom="1px solid #D9D9D9"
        paddingBottom="24px"
      >
        <span style={{ color: "#FFE000" }}>/ </span>projects
      </Typography>

      <Grid container spacing={4} marginTop="44px">
        {projectData.map((project, index) => (
          <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
            <ReactCardFlip
              flipDirection="horizontal"
              isFlipped={isFlippedArray[index]}
            >
              <Card
                sx={{
                  ...getCardSize(index),
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  boxShadow: "none",
                  transition: " height 0.5s",
                }}
                style={{ backgroundColor: getColor(index) }}
              >
                <CardContent sx={{ p: 3.5 }}>
                  <img
                    src={project.icon}
                    alt=""
                    style={{
                      marginBottom: "10px",
                    }}
                  />
                  <Typography
                    fontFamily="Inter"
                    fontSize="21px"
                    fontWeight="500"
                  >
                    {project.title}
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    marginTop="20px"
                    height="0px"
                  >
                    {project.description}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    px: 4,
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    size="small"
                    sx={{ textTransform: "none", mb: 2 }}
                    style={{ color: "#000000" }}
                    onClick={() => flipCard(index)}
                  >
                    <Typography
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      marginTop="20px"
                      borderBottom="1px solid black"
                    >
                      Learn More
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
              <Card
                sx={{
                  ...getCardSize(index),
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  boxShadow: "none",

                  transition: "transform 0.5s, height 0.5s",
                }}
                style={{ backgroundColor: getColor(index) }}
              >
                <CardContent sx={{ p: 3.5 }}>
                  <Typography
                    fontFamily="Inter"
                    fontSize="18px"
                    fontWeight="400"
                  >
                    {project.title}
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    marginTop="20px"
                    sx={{ overflowY: "scroll" }}
                    height="165px"
                  >
                    {project.detailed_desc}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    px: 4,
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{ borderRadius: "20px" }}
                    style={{ color: "#000000" }}
                    onClick={() => flipCard(index)}
                  >
                    <WestIcon />
                  </Button>
                </CardActions>
              </Card>
            </ReactCardFlip>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Projects;
