import React from "react";
import style from "./LnD.module.css";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import downArrow from "../../assets/downArrow.png";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import RemoveIcon from "@mui/icons-material/Remove";
import video1 from "../../assets/353.mp4";
import video2 from "../../assets/744.mp4";
import video3 from "../../assets/736506.mp4";
import video4 from "../../assets/742536.mp4";
import square from "../../assets/square.mp4";
import rect1 from "../../assets/rect1.png";
import rect2 from "../../assets/rect2.png";
import rect3 from "../../assets/rect3.png";
import rect4 from "../../assets/rect4.png";
import rect5 from "../../assets/rect5.png";
import rect6 from "../../assets/rect6.png";

const trainingData = [
  {
    type: "Corporate Training",
    title: "Placement Based Training",
    details: [
      "Follow the Training Plan Discussed (Web Tech)",
      "Perks",
      "3 Months Course",
      "Course break down",
      "Course duration",
      "Faculty",
      "Fee",
      "Assessment criteria",
    ],
    videoUrl:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    backgroundColor: "#FFFCE6",
    link: "",
  },
  {
    type: "Online Training",
    title: "MAJIK (Mastering Java Interview Knack)",
    description:
      "MAJIK helps you prepare for any JAVA interview out there, by diving deep into core java and through assessments and projects. Our 3 Level program containing:",
    details: [
      "MAJIK - LEVEL 1 - OOAD",
      "MAJIK - LEVEL 2 - INTERMEDIATE",
      "MAJIK - LEVEL 3 - ADVANCED",
      "Demo session for Just ₹99",
    ],
    videoUrl:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    backgroundColor: "#EEF0F7",
    link: "/Majik",
  },
];

function LnD() {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{ marginTop: { xs: 1, md: 6 } }}
        >
          <Grid item md={7} xs={12}>
            <Typography
              fontFamily="Inter"
              fontSize="15px"
              color="#FFC700"
              marginBottom="25px"
            >
              L&D
            </Typography>
            <Typography
              fontFamily="Inter"
              sx={{
                fontSize: {
                  xs: "32px",
                  sm: "40px",
                  md: "50px",
                },
              }}
              fontWeight="500"
              maxWidth="568px"
              textAlign="left"
            >
              Tech Upskill: Unleash Digital Potential
            </Typography>
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
            sx={{
              marginTop: {
                xs: "15px",
                sm: "15px",
                md: "60px",
              },
            }}
          >
            <Typography
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              maxWidth="366px"
              textAlign="left"
            >
              Discover innovative IT solutions tailored for robust learning and
              development. From versatile Java to cross-platform Flutter, we
              empower growth through technology.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} marginTop="60px">
          <Grid item lg={7} md={6} xs={12}>
            {/* <VideoPlayer url={video2} height="auto" width="100%" /> */}
            <img src={rect1} width="100%" alt="" />
          </Grid>
          <Grid item lg={5} md={6} xs={12} display="flex">
            {/* <VideoPlayer url={video3} height="auto" width="100%" /> */}
            <img src={rect2} width="100%" alt="" />
          </Grid>
        </Grid>
        <Grid container marginTop="20px" spacing={3} alignItems="baseline">
          <Grid item md={7} xs={12}>
            <Typography
              fontFamily="Inter"
              fontSize="32px"
              fontWeight="300"
              textTransform="uppercase"
              marginTop="100px"
            >
              corporate training{" "}
              <span style={{ marginLeft: "10px" }}>
                <img src={downArrow} />
              </span>
            </Typography>
            <Box maxWidth="400px" marginTop="25px">
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "600",
                  backgroundColor: "#FFF6B3",
                  padding: "8px",
                  fontStyle: "italic",
                }}
              >
                What We Offer?
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Java based
                Training (Freshers/ Experienced)
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                Node.JS, React/Angular
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                Django, React/Angular
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                .Net Core Training
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> React
                Native/Flutter Training
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Android/ IOS
                native Training
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> UI/UX Training
              </Typography>
            </Box>
          </Grid>
          <Grid item md={5} xs={12} marginTop="60px">
            <Typography
              fontFamily="Inter"
              sx={{
                fontSize: { sm: 16, md: 20 },
                fontWeight: { sm: 500, md: 600 },
              }}
              textAlign="left"
              lineHeight="185%"
            >
              We at Msigma Infotech, trains freshers for corporate companies
              helping you significantly reduce your training expenditures and
              time by leveraging our commitment to preparing students for your
              specific technological needs.
            </Typography>
            <Typography
              marginTop="20px"
              fontFamily="Inter"
              sx={{
                fontSize: { sm: 16, md: 20 },
                fontWeight: { sm: 500, md: 600 },
              }}
              textAlign="left"
              lineHeight="185%"
            >
              We offer your company a strategic advantage by providing skilled
              and trained professionals ready for immediate deployment. This
              approach not only saves significant costs in training and salaries
              but also accelerates the project delivery process, enhancing
              overall operational efficiency.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} marginTop="60px">
          <Grid item md={7} xs={12}>
            <Box maxWidth="800px">
              <Typography
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="300"
                textAlign="left"
              >
                Ensure Ready-to-Deploy Talent:
              </Typography>
              <Typography
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                textAlign="left"
                lineHeight="200%"
                marginTop="20px"
              >
                We at Msigma Infotech, trains freshers for corporate companies
                helping you significantly reduce your training expenditures and
                time by leveraging our commitment to preparing students for your
                specific technological needs.
              </Typography>
              <Typography
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                textAlign="left"
                lineHeight="200%"
                marginTop="20px"
              >
                We offer your company a strategic advantage by providing skilled
                and trained professionals ready for immediate deployment. This
                approach not only saves significant costs in training and
                salaries but also accelerates the project delivery process,
                enhancing overall operational efficiency.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            {/* <VideoPlayer url={video4} height="auto" width="100%" /> */}
            <img src={rect3} width="100%" alt="" />
          </Grid>
        </Grid>
        <Grid container spacing={4} marginTop="10px">
          <Grid item md={7} xs={12}>
            <Box maxWidth="800px">
              <Typography
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="300"
                textAlign="left"
              >
                Access to Training Metrics and Standards:
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ mt: 2, color: "#807e7c", lineHeight: 2 }}
              >
                We provide you detailed training and evaluation metrics before
                the placement interviews to ensure that the candidates meet your
                specific standards and expectations
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "600",
                  backgroundColor: "#FFF6B3",
                  padding: "8px",
                  fontStyle: "italic",
                  mt: 2,
                }}
              >
                Additional Benefits:
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <RemoveIcon sx={{ fontSize: 12 }} /> Tailored training programs
                based on your specific needs and technology requirements.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <RemoveIcon sx={{ fontSize: 12 }} /> Experienced trainers with
                industry-relevant knowledge and skills.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <RemoveIcon sx={{ fontSize: 12 }} /> Hands-on training with
                real-world project scenarios.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                {/* <VideoPlayer url={square} height="auto" width="100%" /> */}
                <img src={rect4} width="100%" alt="" />
              </Grid>
              <Grid item xs={6}>
                {/* <VideoPlayer url={square} height="auto" width="100%" /> */}
                <img src={rect5} width="100%" alt="" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container marginTop="200px">
          {trainingData.map((training, index) => (
            <Grid item xs={12} key={index}>
              <Link to={training.link}>
                <Grid
                  container
                  style={{ backgroundColor: training.backgroundColor }}
                  height="auto"
                  alignItems="center"
                  padding="50px"
                >
                  <Grid item md={6} xs={12}>
                    <Typography
                      fontFamily="Inter"
                      fontSize={{ xs: "24px", sm: "28px", md: "36px" }}
                      fontWeight="400"
                    >
                      {training.type}
                    </Typography>

                    <Typography
                      lineHeight="42px"
                      fontStyle="italic"
                      maxWidth="424px"
                      sx={{
                        fontSize: { xs: 16, md: 20 },
                        mt: 1,
                        fontFamily: "inter",
                        fontWeight: "600",
                      }}
                    >
                      {training.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: 12, md: 18 },
                        lineHeight: { xs: 2, md: 2 },
                        mt: 1,
                        fontFamily: "inter",
                        fontWeight: "400",
                      }}
                    >
                      {training.description}
                    </Typography>

                    {training.details.map((detail, idx) => (
                      <Typography
                        key={idx}
                        fontStyle="italic"
                        maxWidth="424px"
                        sx={{
                          fontSize: { xs: 12, md: 18 },
                          lineHeight: { xs: 2, md: 2 },
                          mt: 1,
                          fontFamily: "inter",
                          fontWeight: "400",
                        }}
                      >
                        <FiberManualRecordIcon sx={{ fontSize: 8 }} /> {detail}
                      </Typography>
                    ))}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* <VideoPlayer
                    url={training.videoUrl}
                    height="auto"
                    width="100%"
                  /> */}
                    <img src={rect6} width="100%" alt="" />
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default LnD;
