import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

const CountdownTimer = ({ endDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => {
    return Object.keys(time)
      .map((interval) => {
        if (!time[interval]) {
          return;
        }
        return `${time[interval]} ${interval} `;
      })
      .join("");
  };

  return (
    <Typography
      fontFamily="inter"
      color="white"
      fontWeight="600"
      sx={{ fontSize: { xs: "16px", sm: "20px" } }}
      width="auto"
      marginLeft="10px"
    >
      {timeLeft.days ? formatTime(timeLeft) : "Offer has ended!"}
    </Typography>
  );
};

export default CountdownTimer;
