import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import playBtn from "../../assets/playBtn.png";
import pauseBtn from "../../assets/pauseBtn.png";

function VideoPlayer({ url, height, width }) {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [showPauseButton, setShowPauseButton] = useState(false);

  const handlePlayPause = () => {
    setPlaying((prevPlaying) => !prevPlaying); // Toggles play/pause
  };

  const handleMouseEnter = () => {
    if (playing) {
      setShowPauseButton(true);
    }
  };

  const handleMouseLeave = () => {
    setShowPauseButton(false);
  };

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ReactPlayer
        width={width}
        height={height}
        ref={playerRef}
        playing={playing}
        // controls
        onPlay={() => {}}
        onPause={() => {}}
        url={url}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
      />
      {!playing && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
          onClick={handlePlayPause}
        >
          {/* Your custom play button */}
          <img
            src={playBtn}
            alt="Play Button"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      )}
      {showPauseButton && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
          onClick={handlePlayPause}
        >
          {/* Your custom pause button */}
          <img
            src={pauseBtn}
            alt="Pause Button"
            style={{ width: "50px", height: "50px" }}
          />
        </div>
      )}
    </div>
  );
}

export default VideoPlayer;
