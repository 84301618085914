import React from "react";
import Grid from "@mui/material/Grid";

import sas from "../../../assets/sas.png";
import msg from "../../../assets/msg.png";
import mis from "../../../assets/mis.png";
import mis2 from "../../../assets/mis2.png";
import ggh from "../../../assets/ggh.png";

const images = [
  { src: ggh, alt: "GGH Logo", lg: 3, md: 3, xs: 6 },
  { src: sas, alt: "SAS Logo", lg: 2, md: 3, xs: 6 },
  { src: msg, alt: "MSG Logo", lg: 2, md: 3, xs: 6 },
  { src: mis, alt: "MIS Logo", lg: 2, md: 3, xs: 6 },
  { src: mis2, alt: "MIS2 Logo", lg: 3, md: 3, xs: 6 },
];

const Logos = () => {
  return (
    <Grid
      container
      spacing={4}
      justifyContent="space-between"
      marginBottom="100px"
    >
      {images.map((image, index) => (
        <Grid
          item
          {...image}
          key={index}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={image.src} alt={image.alt} style={{ opacity: ".6" }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Logos;
