import React, { useState } from "react";
import style from "./Contact.module.css";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

function About() {
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [message, setMessage] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      fullName.trim() !== "" &&
      emailId.trim() !== "" &&
      message.trim() !== ""
    ) {
      if (isFullNameValid && isEmailValid) {
        setIsSubmitting(true);

        const SteinStore = require("stein-js-client");
        const store = new SteinStore(
          "https://api.steinhq.com/v1/storages/65a51e952acf6d63de7b880f"
        );

        const formData = {
          Name: fullName,
          Email: emailId,
          Message: message,
          timestamp: new Date().toLocaleString(),
        };

        store
          .append("Contactform", [formData])
          .then(() => {
            setFullName("");
            setEmailId("");
            setMessage("");
            setIsFormSubmitted(true);

            alert("Submitted Successfully");
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            alert(
              "Error occurred while submitting the form. Please try again."
            );
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        console.error(
          "Form validation failed. Please fill in all the required fields correctly."
        );
        alert("Please fill in all the required fields correctly.");
      }
    } else {
      console.error("Form fields cannot be blank.");
      alert("Please fill in all the fields.");
    }
  };

  // Email Validation
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailId(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
  };

  // Full name validation
  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);
    // Check if first name is not empty
    setIsFullNameValid(fullName.trim() !== "");
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Grid
          container
          alignItems="center"
          sx={{ marginTop: { xs: 1, md: 6 } }}
        >
          <Grid item md={6} xs={12} marginTop="-30px" marginBottom="30px">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  fontFamily="Inter"
                  fontSize="15px"
                  color="#FFC700"
                  marginBottom="15px"
                >
                  Contact
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontFamily="Inter"
                  sx={{
                    fontSize: {
                      xs: "32px",
                      sm: "40px",
                      md: "50px",
                    },
                  }}
                  fontWeight="400"
                  maxWidth="568px"
                  textAlign="left"
                >
                  Hey, Let’s Talk
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  maxWidth="366px"
                  textAlign="left"
                >
                  Get in touch with us today to discuss how our innovative
                  solutions can drive your business forward.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card
              sx={{
                minWidth: 275,
                boxShadow: "none",
                backgroundColor: "#FFFCE8",
                padding: "40px",
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12} marginTop="10px">
                    <Typography>FULL NAME</Typography>
                    <TextField
                      placeholder="John Doe"
                      value={fullName}
                      variant="standard"
                      fullWidth
                      onChange={handleFullNameChange}
                      error={!isFullNameValid}
                      helperText={
                        !isFullNameValid ? "Full name cannot be empty" : ""
                      }
                      sx={{
                        justifyContent: "center",
                        padding: "10px",
                        maxWidth: "700px",
                        height: "60px",
                        backgroundColor: "white",
                        "& input": {
                          backgroundColor: "white",
                          caretColor: "white",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop="40px">
                    <Typography>EMAIL ADDRESS</Typography>
                    <TextField
                      placeholder="johndoe@email.com"
                      value={emailId}
                      variant="standard"
                      fullWidth
                      onChange={handleEmailChange}
                      error={!isEmailValid}
                      helperText={!isEmailValid ? "email cannot be empty" : ""}
                      sx={{
                        justifyContent: "center",
                        padding: "10px",
                        maxWidth: "700px",
                        height: "60px",
                        backgroundColor: "white",
                        "& input": {
                          backgroundColor: "white",
                          caretColor: "white",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop="40px">
                    <Typography>MESSAGE</Typography>
                    <TextField
                      placeholder="Type your message here"
                      value={message}
                      variant="standard"
                      fullWidth
                      multiline
                      rows={4}
                      onChange={(e) => setMessage(e.target.value)}
                      sx={{
                        justifyContent: "center",
                        padding: "10px",
                        maxWidth: "700px",

                        backgroundColor: "white",
                        "& input": {
                          backgroundColor: "white",
                          caretColor: "white",
                        },
                      }}
                    />
                  </Grid>
                  <Button
                    className="contactBtn"
                    onClick={handleSubmit}
                    style={{
                      background: "black",
                      borderRadius: "50px",
                      padding: "15px 30px 15px 30px",
                      marginTop: "15px",
                    }}
                    variant="contained"
                  >
                    {isSubmitting ? "Submitting..." : "Contact Us"}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container marginTop="150px">
          <Grid item md={6} xs={12} marginTop="-50px">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  fontFamily="Inter"
                  fontSize="15px"
                  color="#FFC700"
                  marginBottom="15px"
                >
                  FAQ
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontFamily="Inter"
                  sx={{
                    fontSize: {
                      xs: "32px",
                      sm: "40px",
                      md: "50px",
                    },
                  }}
                  fontWeight="400"
                  maxWidth="568px"
                  textAlign="left"
                >
                  Common
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontFamily="Inter"
                  sx={{
                    fontSize: {
                      xs: "32px",
                      sm: "40px",
                      md: "50px",
                    },
                  }}
                  fontWeight="400"
                  maxWidth="568px"
                  textAlign="left"
                  marginTop="-10px"
                >
                  Questions
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              style={{
                boxShadow: "none",
                margin: "30px",
                borderTop: "none",
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  border: "none",
                }}
              >
                <Typography sx={{ width: "83%", flexShrink: 0 }}>
                  What services do you offer?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We offer a comprehensive range of technology services,
                  including software development, web development, mobile app
                  development, UI/UX design, and more.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              style={{
                boxShadow: "none",
                margin: "30px",
                borderTop: "none",
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel2" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                sx={{
                  border: "none",
                }}
              >
                <Typography sx={{ width: "83%", flexShrink: 0 }}>
                  Can you help with rebranding Website/Logo?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, we provide rebranding services for websites and logos.
                  Our team of designers and developers can help revamp your
                  brand identity to align with your business goals.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              style={{
                boxShadow: "none",
                margin: "30px",
                borderTop: "none",
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel3" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                sx={{
                  border: "none",
                }}
              >
                <Typography sx={{ width: "83%", flexShrink: 0 }}>
                  Do you have a research team?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, we have a dedicated research team that stays abreast of
                  the latest technological trends and developments. They play a
                  crucial role in ensuring that our solutions remain innovative
                  and cutting-edge.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              style={{
                boxShadow: "none",
                margin: "30px",
                borderTop: "none",
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel4" ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                sx={{
                  border: "none",
                }}
              >
                <Typography sx={{ width: "83%", flexShrink: 0 }}>
                  How do you measure the success of the company?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We measure the success of our company through various metrics,
                  including customer satisfaction, project delivery timelines,
                  employee retention rates, and business growth. These
                  indicators help us gauge our performance and make informed
                  decisions for continuous improvement.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Grid container className={style.getInTouch} alignItems="center">
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              fontFamily="Inter"
              fontWeight="400"
              sx={{
                fontSize: {
                  xs: "28px",
                  sm: "32px",
                  md: "40px",
                },
              }}
            >
              Let's start building your
            </Typography>
            <Typography
              fontFamily="Inter"
              fontWeight="400"
              sx={{
                fontSize: {
                  xs: "28px", // Adjust these values as needed for different screen sizes
                  sm: "32px",
                  md: "40px",
                  lg: "48px",
                  xl: "56px",
                },
              }}
            >
              unique idea together.
            </Typography>
            <Button
              style={{
                background: "black",
                borderRadius: "50px",
                padding: "15px 30px 15px 30px",
                marginTop: "15px",
              }}
              variant="contained"
            >
              Get in Touch
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default About;
