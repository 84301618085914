import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import gg from "../../../../src/assets/gg.png";
import manu from "../../../../src/assets/manu.png";
import hari from "../../../../src/assets/hari.png";
import ashir from "../../../../src/assets/ashir.png";
import ranjan from "../../../../src/assets/ranjan.png";
import user from "../../../../src/assets/user.png";
import kiran from "../../../../src/assets/kiran.png";

const teamMembers = [
  // {
  //   name: "Hari Govind S",
  //   position: "CTO",
  //   image: user,
  // },
  {
    name: "Renjan Roy",
    position: "Executive Director",
    image: ranjan,
  },
  {
    name: "Hari Krishnan",
    position: "COO",
    image: hari,
  },
  // {
  //   name: "Suneesh Thampi",
  //   position: "L&D Head",
  //   image: user,
  // },
  {
    name: "Kiran UK",
    position: "Head of Design",
    image: kiran,
  },
  {
    name: "Ashir Ali",
    position: "Head of Engineering",
    image: ashir,
  },
];

const OurTeam = () => {
  return (
    <>
      <Typography
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="300"
        textTransform="uppercase"
        borderBottom="1px solid #D9D9D9"
        paddingBottom="24px"
        marginTop="100px"
      >
        <span style={{ color: "#FFE000" }}>/ </span>team
      </Typography>

      <Grid container spacing={3} marginTop="30px">
        {teamMembers.map((member, index) => (
          <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
            <Card
              sx={{ maxWidth: "379px", height: "536" }}
              style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
            >
              <CardMedia
                component="img"
                image={member.image}
                alt={member.name}
              />
              <CardContent>
                <Typography fontFamily="Inter" fontSize="22px" fontWeight="400">
                  {member.name}
                </Typography>
                <Typography
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="#999696"
                >
                  {member.position}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default OurTeam;
