import React, { useState } from "react";
import style from "./MajikNew.module.css";
import CountdownTimer from "./CountDownTimer";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DoneIcon from "@mui/icons-material/Done";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MonitorIcon from "@mui/icons-material/Monitor";
import ExtensionIcon from "@mui/icons-material/Extension";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import majik from "../../assets/majik.jpg";
import suneesh from "../../assets/suneesh.png";

function MajikNew() {
  const endDate = new Date("2024-02-28T23:59:59");
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    setIsLoading(true);

    setTimeout(() => {
      window.location.href = "https://rzp.io/l/PNHKaCk";
    }, 1000);
  };
  return (
    <div className={style.wrapper}>
      <Box
      // paddingBottom="30px"
      // sx={{ width: "100%", my: 10, border: "5px solid #FFE000" }}
      >
        <Grid container textAlign="center">
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography
              sx={{
                fontSize: { xs: 17, md: 26 },
              }}
              fontWeight="600"
              border="2px solid #ffe000"
              width="auto"
              padding="10px 20px 10px 20px"
              fontFamily="inter"
              color="#ffe000"
              marginBottom="35px"
            >
              MASTERING JAVA INTERVIEW KNACK
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ whiteSpace: "break-spaces" }}>
            <Typography
              color="white"
              fontWeight="600"
              sx={{
                fontSize: { xs: 21, md: 31 },
                lineHeight: { xs: "1.5", md: "1.8" },
              }}
              fontFamily="inter"
            >
              Embrace this journey to transform into an{" "}
              <span style={{ color: "#ffe000" }}>
                industry-ready professional
              </span>
              , ready to <span style={{ color: "#ffe000" }}>conquer</span> any
              challenge.
            </Typography>

            <Typography
              color="white"
              fontWeight="600"
              sx={{
                fontSize: { xs: 21, md: 31 },
                lineHeight: { xs: "1.5", md: "1.8" },
              }}
              fontFamily="inter"
            >
              Join us and{" "}
              <span style={{ color: "#ffe000" }}>unlock your potential</span>!
            </Typography>
            <Typography
              bgcolor="#ffe000"
              width="auto"
              marginTop="40px"
              color="black"
              fontWeight="600"
              sx={{
                fontSize: { xs: 21, md: 31 },
                lineHeight: { xs: "1.5", md: "1.8" },
              }}
              fontFamily="inter"
            >
              Fully Refundable Demo Session
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          marginTop="30px"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs="12" md="4" justifyContent="center">
            <Card
              style={{
                width: "100%",
                minHeight: "520px",
                background: "#1b1b1b",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "10px",
                justifyContent: "center",
              }}
            >
              <CardMedia style={{ display: "contents" }}>
                {" "}
                <img
                  src={majik}
                  width="90%"
                  alt=""
                  style={{ border: "1px solid #ffe000" }}
                />
              </CardMedia>
              <CardContent>
                <Typography
                  fontFamily="inter"
                  color="white"
                  fontSize="22px"
                  fontWeight="400"
                  textAlign="center"
                >
                  Join our exclusive LIVE demo webinar and crack the Java coding
                  interview with confidence!
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={style.popInOut}
                  style={{
                    width: "auto",
                    background: "#ffe000",
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "inter",
                    textWrap: "nowrap",
                  }}
                >
                  {isLoading ? (
                    "Redirecting..."
                  ) : (
                    <>
                      Register Now at <br />
                      <span style={{ margin: "0 5px" }}>
                        <span style={{ fontSize: "14px", margin: "0 5px" }}>
                          <strike>₹999</strike>
                        </span>
                        <span style={{ fontWeight: "800" }}>₹99</span>
                      </span>
                    </>
                  )}
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs="12" md="5">
            <Grid container spacing="4">
              <Grid item xs="12">
                <Grid container spacing="4">
                  <Grid item xs={6} md={6}>
                    <div className={style.smallCard}>
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            {" "}
                            <CalendarMonthIcon
                              style={{ marginRight: "5px" }}
                            />{" "}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            Date:
                          </Typography>
                          <Typography
                            fontSize="16.5px"
                            color="white"
                            style={{ marginLeft: "10px" }}
                          >
                            28/03/2023
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item xs={6} md={6} textAlign="center">
                    <div className={style.smallCard}>
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            {" "}
                            <AccessTimeIcon
                              style={{ marginRight: "5px" }}
                            />{" "}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            Course Duration:
                          </Typography>
                          <Typography
                            fontSize="16.5px"
                            color="white"
                            style={{ marginLeft: "10px" }}
                          >
                            60 Hours
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} textAlign="center">
                    <div className={style.smallCard}>
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            {" "}
                            <AccessTimeIcon
                              style={{ marginRight: "5px" }}
                            />{" "}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            Demo Session:
                          </Typography>
                          <Typography
                            fontSize="16.5px"
                            color="white"
                            style={{ marginLeft: "10px" }}
                          >
                            1 Hour
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} textAlign="center">
                    <div className={style.smallCard}>
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            {" "}
                            <QuestionAnswerIcon
                              style={{ marginRight: "5px" }}
                            />{" "}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            Dedicated Q&A Session
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} textAlign="center">
                    <div className={style.smallCard}>
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            {" "}
                            <MonitorIcon style={{ marginRight: "5px" }} />{" "}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            Uncover Insider Secrets
                          </Typography>
                          {/* <Typography
                            fontSize="16.5px"
                            color="white"
                            style={{ marginLeft: "10px" }}
                          >
                            28/03/2023
                          </Typography> */}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} textAlign="center">
                    <div className={style.smallCard}>
                      <Grid
                        container
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            {" "}
                            <ExtensionIcon
                              style={{ marginRight: "5px" }}
                            />{" "}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            fontSize="16.5px"
                            color="#ffe000"
                            fontWeight="500"
                            fontFamily="inter"
                          >
                            3 Levels of Training Program
                          </Typography>
                          {/* <Typography
                            fontSize="16.5px"
                            color="white"
                            style={{ marginLeft: "10px" }}
                          >
                            28/03/2023
                          </Typography> */}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: { xs: "0px !important", md: "-30px !important" },
                }}
                justifyContent="center"
                display="flex"
              >
                <Grid container alignItems="center" width="95%">
                  <Grid item xs={7}>
                    <Typography
                      fontFamily="inter"
                      color="#ffe000"
                      fontWeight="600"
                      sx={{
                        fontSize: { xs: 18, md: 24 },
                      }}
                    >
                      Suneesh Thampi
                    </Typography>
                    <Typography
                      fontFamily="inter"
                      color="white"
                      sx={{
                        fontSize: { xs: 14, md: 16 },
                      }}
                      marginBottom="10px"
                    >
                      Corporate Trainer | Freelancer | 10 yrs in IT
                    </Typography>
                    <Typography
                      fontFamily="inter"
                      color="white"
                      sx={{
                        fontSize: { xs: 14, md: 16 },
                      }}
                      marginBottom="10px"
                    >
                      Expert in: Core Java, JEE, Spring MVC, Spring Boot,
                      JavaScript, React JS, Angular JS, Node JS, AWS, Hibernate,
                      MongoDB, Agile Scrum
                    </Typography>
                  </Grid>

                  <Grid item xs={5} marginLeft="-15px">
                    <img src={suneesh} className={style.suneeshImg} alt="" />
                  </Grid>

                  <Grid item xs={12} marginTop="-20px" width="100%">
                    <div
                      className={style.suneeshCard}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        fontFamily="inter"
                        color="white"
                        fontWeight="600"
                        sx={{ fontSize: { xs: "16px", sm: "20px" } }}
                        width="auto"
                      >
                        Offer Ends in:
                      </Typography>
                      <CountdownTimer endDate={endDate} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default MajikNew;
