import React from "react";

import style from "./Background.module.css";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import homeBG from "../../../assets/bg.png";
import mobBG from "../../../assets/bgMob.png";
import upArrow from "../../../assets/upArrow.png";

const Background = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.deskTopWrapper}>
        <div className={style.deskTopContent}>
          <img src={upArrow} alt="" style={{}} />

          <Typography fontFamily="Inter" fontSize="60px" fontWeight="500">
            Next Level
            <span
              style={{
                marginLeft: "10px",
                backgroundColor: "#FFE000",
                px: 1,
                color: "black",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              Innovation
            </span>
          </Typography>

          <Typography fontFamily="Inter" fontSize="60px" fontWeight="500">
            In Technology
          </Typography>

          <Typography
            fontFamily="Inter"
            sx={{
              fontSize: {
                sm: "14px",
                md: "15px",
              },
            }}
            fontWeight="500"
            maxWidth="500px"
          >
            We have helped clients in diverse industries - from school and
            colleges to hospitals, Ed-tech companies and logistics providers -
            with their digital transformations, product development, business
            modernization needs and challenges.
          </Typography>

          <Button
            onClick={() => {
              navigate("/Contact");
            }}
            variant="contained"
            endIcon={<ArrowRightAltIcon />}
            style={{
              backgroundColor: "#FFE000",
              height: "55px",
              width: "186px",
              color: "black",
            }}
            sx={{ borderRadius: "44px", textTransform: "none", mt: 4 }}
          >
            Let's Connect
          </Button>
        </div>
      </div>
      <div className={style.responsiveWrapper}>
        <div>
          <img src={upArrow} alt="" height={36} width={36} />

          <Typography
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="500"
            whiteSpace="nowrap"
          >
            Next Level
            <span
              style={{
                marginLeft: "5px",
                backgroundColor: "#FFE000",
                px: 1,
                color: "black",
                paddingLeft: "7px",
                paddingRight: "7px",
              }}
            >
              Innovation
            </span>
          </Typography>

          <Typography fontFamily="Inter" fontSize="32px" fontWeight="500">
            In Technology
          </Typography>

          <Typography
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            maxWidth="500px"
            marginTop="15px"
          >
            We have helped clients in diverse industries - from school and
            colleges to hospitals, Ed-tech companies and logistics providers -
            with their digital transformations, product development, business
            modernization needs and challenges.
          </Typography>

          <Button
            onClick={() => {
              navigate("/Contact");
            }}
            variant="contained"
            endIcon={<ArrowRightAltIcon />}
            style={{
              backgroundColor: "#FFE000",
              height: "40px",
              width: "140px",
              color: "black",
            }}
            sx={{
              borderRadius: "44px",
              textTransform: "none",
              mt: 2,
              boxShadow: "none",
            }}
          >
            <Typography fontFamily="Inter" fontSize="10px" fontWeight="500">
              Let's Connect
            </Typography>
          </Button>
        </div>
      </div>
      <div className={style.mobileBG} style={{ marginTop: "50px" }}>
        <img
          src={mobBG}
          alt=""
          style={{
            objectFit: "cover",
            width: "100vw",
            maxHeight: "50vh",
          }}
        />
      </div>
    </>
  );
};

export default Background;
