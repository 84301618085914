import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import style from "./PriceCard.module.css";

const plansData = [
  {
    title: "Master Java Interview Knack (MAJIK )",
    level: "LEVEL 1",
    duration: "WEEK 5 (10 HRS)",
    topics: [
      "Introduction to Java from basics",
      "Real-time case study",
      "Environment Set Up",
      "Real-time practical implementation of OOP concepts",
      "Encapsulation",
      "Inheritance - Aggregation, Composition & IS-A",
      "Data Abstraction and Layering",
      "Polymorphism",
    ],
    evaluation: "Evaluation to Join L2 (1 week time)",
  },
  {
    title: "INTERMEDIATE",
    level: "LEVEL 2",
    duration: "(20 HRS)",
    topics: [
      "Constructor and POJO Class",
      "Object Handling",
      "Understanding Cardinality Between Different Classes",
      "Understanding real-time business scenario and design and develop classes and implement the application logic",
      "Introduction to multiple object storage and data structure",
      "Interface",
      "Exception Handling",
      "Introduction to Database",
      "Establishing connection to database using Java",
      "Understanding static modifier",
      "Understanding final, finally, throw and throws",
      "Interview Hacks",
      "Case Studies",
    ],
  },
  {
    title: "ADVANCED",
    level: "LEVEL 3",
    duration: "(30 HRS)",
    topics: [
      "Collections, ArrayList, Sorting Objects",
      "Comparable and Comparator, Linked List, Vector",
      "Set, TreeSet, Map, HashMap",
      "Industry used design patterns to connect to Database (DAO design pattern)",
      "Solid Principles",
      "Most commonly used designed patterns in the industry",
      "Java 8 Features: - API Streams, Date Time API and Functional Interface",
      "Lambda Expressions",
      "Main Project with Database, Bonus",
      "Resume building session and Placement Assistance",
      "Discussion on current interview questions",
      "Overview of Spring and Spring Boot",
      "Overview of HTML, CSS and JS",
    ],
  },
];

const PriceCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    setIsLoading(true);

    setTimeout(() => {
      window.location.href = "https://rzp.io/l/PNHKaCk";
    }, 1000);
  };
  return (
    <Box paddingBottom="30px" sx={{ width: "100%", my: 10 }}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        padding="30px"
        textAlign="center"
      >
        <Typography
          fontFamily="Inter"
          sx={{
            fontSize: { xs: 24, md: 40 },
          }}
          fontWeight="500"
          borderBottom="2px solid #ffe000"
          marginBottom="20px"
          color="white"
        >
          Program Details
        </Typography>
        <Typography
          fontFamily="Inter"
          marginBottom="15px"
          fontSize="14px"
          sx={{
            fontSize: { xs: 24, md: 40 },
          }}
          fontWeight="800"
          color="#575757"
        >
          MASTERING{" "}
          <span
            className={style.javaText}
            style={{ fontSize: { xs: 24, md: 40 } }}
          >
            JAVA
          </span>
          INTERVIEW KNACK
        </Typography>
      </Box>

      <Grid
        container
        rowSpacing={1}
        sx={{ mt: 0 }}
        xs={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {plansData.map((plan, index) => (
          <Grid
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            key={index}
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
              minHeightheight: "740px",
              width: "33%",
              paddingBottom: "40px",
              ...(index % 2 === 1 && { backgroundColor: "#1b1b1b" }),
            }}
          >
            <Box>
              <Box sx={{ px: 4, pt: 4 }}>
                <Typography
                  color="#ffe000"
                  fontFamily="inter"
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontWeight: "600",
                    mt: 1,
                  }}
                >
                  {plan.title}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 1,
                  }}
                >
                  <Typography
                    color="white"
                    fontFamily="inter"
                    variant="body2"
                    gutterBottom
                    sx={{
                      fontWeight: "600",
                      mt: 1,
                      fontSize: "20px",
                    }}
                  >
                    {plan.level}
                  </Typography>
                  <Typography
                    fontFamily="inter"
                    variant="body2"
                    gutterBottom
                    sx={{
                      fontWeight: "600",
                      mt: 1,
                      fontSize: "15px",
                      color: "#898989",
                    }}
                  >
                    {plan.duration}
                  </Typography>
                </Box>

                {plan.topics.map((topic, idx) => (
                  <Typography
                    key={idx}
                    color="white"
                    fontFamily="inter"
                    variant="body2"
                    gutterBottom
                    sx={{
                      mt: idx === 0 ? 2 : 1,
                      lineHeight: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontWeight: "medium",
                      fontSize: "13px",
                    }}
                  >
                    <DoneIcon
                      sx={{
                        fontSize: 20,
                        color: "#12B005",
                        fontWeight: "bold",
                      }}
                    />
                    {topic}
                  </Typography>
                ))}

                {plan.evaluation && (
                  <Typography
                    color="white"
                    fontFamily="inter"
                    variant="body2"
                    gutterBottom
                    sx={{
                      mt: 2,
                      lineHeight: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontWeight: "medium",
                      textTransform: "uppercase",
                      fontSize: "13px",
                    }}
                  >
                    {plan.evaluation}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid display="flex" justifyContent="center">
        <Button
          onClick={handleSubmit}
          style={{
            background: "#FFE000",
            marginTop: "15px",
            borderRadius: "0",
            padding: "0",
            overflow: "hidden",
            transform: "skewX(-20deg)",
          }}
          variant="contained"
        >
          <span
            style={{
              width: "250px",
              fontSize: "18px",
              display: "block",
              padding: "16px 30px 16px 30px",
              color: "black",
              fontWeight: 800,
              transform: "skewX(20deg)",
            }}
          >
            {isLoading ? "Redirecting..." : "Get Demo Now"}
          </span>
        </Button>
      </Grid>
    </Box>
  );
};

export default PriceCard;
