import React from "react";
import style from "./About.module.css";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import OurTeam from "../../components/About/OurTeam/OurTeam";
import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import gopalan from "../../assets/gopalan.png";
import manu from "../../assets/manu.png";
import downArrow from "../../assets/downArrow.png";

const leadershipData = [
  {
    role: "Chairman",
    name: "Sri. A.M. Gopalan",
    image: gopalan,
    para1:
      "Chairman of Sree Gokulam Group of Companies. Everyone's Gokulam Gopalan, he himself is an inspiration to anyone who has the willpower to work for their dreams. From his very humble beginnings he has carved a legacy that will be written in royal gold with pure dedication and perseverance. ",
    para2:
      "He runs a number of institutions including colleges, hospitals, research foundation, and several public schools in and out of Kerala. Under his spirited and dynamic leadership the company is now doing well with a turnover of more than 4000 crore rupees and has more than 8000 dedicated people working under various Gokulam ventures including Sree Gokulam Chit and Finance Co.Pvt. Ltd., Sree Gokulam Medical college, Trivandrum. Sree Gokulam Public School (Vadakara and Trivandrum), Sree Gokulam Hotels Pvt. Ltd. (Gokulam Park Inn Cochin; Gokulam Park, Chennai; Gayathri International, Calicut); Gokulam Nalanda Resorts, Neeleshwaram; Gokulam Sree Magazine; Sree Gokulam Food Products; Sree Gokulam Holy Aqua Mineral Water; Sree Gokulam Handicrafts; Shivapriya Ayurvedic Hospitals Pvt. Ltd. Kollam; Gokulam Cards, Chennai; Sree Gokulam Exports & Imports; Sree Gokulam Herbs & Biotech; Sree Gokulam Movies and many more. He is a great selfless human being and a philanthropist, with an absolute attitude to contribute to the society by service through business.",
    para3:
      "He has been conferred with the prestigious “GEM OF INDIA ’96” and “UDYOG RATHNA ’97” award for his outstanding contribution to the economic development of the country.",
  },
  {
    role: "CEO",
    name: "Manu M Rajan",
    image: manu,
    para1:
      "The visionary pillar of our IT company. A passionate and dedicated engineering graduate from Government College of Engineering, Kannur, with a wealth of industry experience and an unwavering commitment to excellence.",
    para2:
      "Driven by his love for teaching and a relentless desire to make a difference, He has successfully transformed the landscape of industries he touched through his ventures. His journey reflects a deep-seated passion for innovation and a commitment to empowering individuals to reach their full potential",
    para3:
      "Now, as he ventures into the world of IT, He carries his passion on his sleeves, infusing the same dedication and commitment that have defined his previous successes. With a strong foundation in engineering and a proven track record of leadership, He is poised to lead our IT company to new heights, embracing challenges with enthusiasm and shaping a future where innovation thrives.",
  },
];

function About() {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{ marginTop: { xs: 1, md: 6 } }}>
          <Grid item md={7} xs={12}>
            <Typography
              fontFamily="Inter"
              fontSize="15px"
              color="#FFC700"
              marginBottom="25px">
              About us
            </Typography>
            <Typography
              fontFamily="Inter"
              sx={{
                fontSize: {
                  xs: "32px",
                  sm: "40px",
                  md: "50px",
                },
              }}
              fontWeight="500"
              maxWidth="568px"
              textAlign="left">
              Innovating Today's Technology Landscape
            </Typography>
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
            sx={{
              marginTop: {
                xs: "15px",
                sm: "15px",
                md: "60px",
              },
            }}>
            <Typography
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              maxWidth="366px"
              textAlign="left">
              MSigma Infotech pioneers today's tech innovation, forging
              solutions that redefine industries and shape tomorrow's
              technological landscape.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} marginTop="60px">
          <Grid item md={7} xs={12}>
            <img
              style={{ objectFit: "cover" }}
              src={img1}
              alt=""
              width="98%"
              height="400px"
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <img
              style={{ objectFit: "cover" }}
              src={img2}
              alt=""
              width="98%"
              height="400px"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} marginTop="60px">
          <Grid item md={7} xs={12}>
            <Typography
              fontFamily="Inter"
              fontSize="32px"
              fontWeight="300"
              textTransform="uppercase">
              Our Story
              <span style={{ marginLeft: "10px" }}>
                <img src={downArrow} />
              </span>
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  fontFamily="Inter"
                  fontWeight="600"
                  color="#171717"
                  marginBottom="30px"
                  lineHeight="190%"
                  sx={{
                    fontSize: {
                      xs: "17px",
                      sm: "19px",
                      md: "20px",
                    },
                  }}>
                  We have helped clients in diverse industries - from school and
                  colleges to hospitals, Ed-tech companies and logistics
                  providers - with their digital transformations, product
                  development, business modernization needs and challenges.
                </Typography>
                {/* <Typography
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="#535353"
                  marginBottom="30px"
                  lineHeight="190%"
                >
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                </Typography> */}
                {/* <Typography
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="#535353"
                  lineHeight="190%"
                >
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum' will uncover many web sites still in their
                  infancy. Various versions have evolved over the years,
                  sometimes by accident, sometimes on purpose (injected humour
                  and the like).
                </Typography> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container marginTop="100px">
          {leadershipData.map((leader, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="300"
                textTransform="uppercase"
                borderBottom="1px solid #D9D9D9"
                paddingBottom="24px">
                <span style={{ color: "#FFE000" }}>/ </span>Our {leader.role}
              </Typography>
              <Card
                sx={{ minWidth: 275, boxShadow: "none", marginTop: "30px" }}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <img src={leader.image} alt="" width="95%" />
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            fontFamily="Inter"
                            fontSize="20px"
                            fontWeight="600"
                            marginBottom="25px">
                            {leader.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="400"
                            color="#535353"
                            lineHeight="190%"
                            marginBottom="25px"
                            dangerouslySetInnerHTML={{
                              __html: leader.para1,
                            }}
                          />
                          <Typography
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="400"
                            color="#535353"
                            lineHeight="190%"
                            marginBottom="25px"
                            dangerouslySetInnerHTML={{
                              __html: leader.para2,
                            }}
                          />
                          <Typography
                            fontFamily="Inter"
                            fontSize="16px"
                            fontWeight="400"
                            color="#535353"
                            lineHeight="190%"
                            dangerouslySetInnerHTML={{
                              __html: leader.para3,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <OurTeam />
      </div>
    </div>
  );
}

export default About;
