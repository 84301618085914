import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import style from "./Navbar.module.scss";
import clsx from "clsx";
import Hamburger from "hamburger-react";

import Logo from "../../assets/Infologo.png";

const MENU_LIST = [
  { text: "Home", href: "/" },
  { text: "About Us", href: "/About" },
  // { text: "Clients", href: "/Clients" },
  // { text: "Projects", href: "/Projects" },
  { text: "Services", href: "/Services" },
  { text: "L&D", href: "/LnD" },
  { text: "Career", href: "/Career" },
  // { text: "Blogs", href: "/Blogs" },
];

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const activeIndex = MENU_LIST.findIndex(
    (menu) => menu.href === location.pathname
  );
  const getLinkStyle = (idx) => {
    return idx === activeIndex
      ? { paddingBottom: "10px", borderBottom: "3px solid #ffe000" }
      : {};
  };

  const [isMobile, setIsMobile] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  function handleResize() {
    const { innerWidth } = window;
    if (innerWidth < 1100) setIsMobile(true);
    else setIsMobile(false);
  }

  function toggleMenu() {
    setIsOpenMenu((prev) => !prev);
  }

  function closeMenu() {
    setIsOpenMenu(false);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpenMenu) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [isOpenMenu]);

  const [position, setPosition] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.scrollY;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "visible" : "hidden";
  return (
    <div className={style.wrapper}>
      <nav className={`${style.nav} ${style[cls]}`}>
        <a href={"/"}>
          <img alt="msigma Logo" src={Logo} width="200px" height="auto" />
        </a>

        {!isMobile && (
          <div className={style.desktopMenu}>
            {MENU_LIST.map((menu, idx) => (
              <div key={menu.text}>
                <Link to={menu.href} style={getLinkStyle(idx)}>
                  {menu.text}
                </Link>
              </div>
            ))}
            <button
              style={{ cursor: "pointer" }}
              className={style.contactBtn}
              onClick={() => {
                navigate("/Contact");
              }}
            >
              Contact Us
            </button>
          </div>
        )}

        {isMobile && (
          <>
            <div className={style.ham}>
              <Hamburger toggled={isOpenMenu} toggle={toggleMenu} />
            </div>

            <div
              className={clsx(
                style.menuList,
                style.mobile,
                isOpenMenu && style.open
              )}
            >
              {MENU_LIST.map((menu, idx) => (
                <div key={menu.text}>
                  <Link
                    to={menu.href}
                    className={
                      idx === activeIndex
                        ? `${style.navlink} ${style.mobActive}`
                        : style.navlink
                    }
                    onClick={() => {
                      setIsOpenMenu(false);
                    }}
                  >
                    {menu.text}
                  </Link>
                </div>
              ))}
              <button
                style={{ cursor: "pointer" }}
                className={style.contactBtn}
                onClick={() => {
                  navigate("/Contact");
                  setIsOpenMenu(false);
                }}
              >
                Contact Us
              </button>
            </div>
          </>
        )}
      </nav>
    </div>
  );
}
