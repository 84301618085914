import React from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.css";
import Logo from "../../assets/Infologo.png";
import Fb from "../../assets/fbLogo.png";
import Ig from "../../assets/igLogo.png";
import X from "../../assets/xLogo.png";

function Footer() {
  return (
    <footer>
      <div className={style.top}>
        <div className={`${style.content} ${style.left}`}>
          <div className={style.sect2}>
            <img src={Logo} alt="" width="200px" height="auto" />
            <div className={style.sectionItems}>
              <div className={style.sectionItemLeft}>
                <Link to="https://www.facebook.com/">
                  {" "}
                  <img src={Fb} alt="fb" />
                </Link>
                <Link to="https://www.instagram.com/msigmainfotech/">
                  {" "}
                  <img src={Ig} alt="ig" />
                </Link>
                <Link to="https://www.x.com/">
                  {" "}
                  <img src={X} alt="x" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={style.pages}>
          <ul>
            <div className={style.sectionHeading}>QUICK LINKS</div>
            <div className={style.sectionItems}>
              <div>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/About">About Us</Link>
                </li>
                <li>
                  <Link to="/Services">Services</Link>
                </li>
              </div>
              <div>
                <li>
                  <Link to="/LnD">LnD</Link>
                </li>
                <li>
                  <Link to="/Career">Career</Link>
                </li>
                {/* <li>
                  <Link to="/Blogs">Blogs</Link>
                </li> */}
              </div>
            </div>
          </ul>

          <ul>
            <div className={style.sectionHeading}>TRAINING</div>

            <li>
              <Link to="/">Corporate Training</Link>
            </li>
            <li>
              <Link to="/">Offline Training</Link>
            </li>
            <li>
              <Link to="/">Online Training</Link>
            </li>
          </ul>

          <ul>
            <div className={style.sectionHeading}>CONNECT US</div>
            <li>
              <a href="tel:+91 91889 22780">+91 91889 22780</a>
            </li>
            <li>
              <a href="mailto:harikrishnan@msigmagokulam.com">
                harikrishnan@msigmagokulam.com
              </a>
            </li>
            <li>
              <Link to="https://www.google.com/maps/place/Msigma+Gokulam/@8.5364936,76.9224721,18.82z/data=!4m7!3m6!1s0x3b05b9523836d0cd:0x8c4368c4da964a6e!4b1!8m2!3d8.5362957!4d76.9229032!16s%2Fg%2F11smm1trmn?entry=ttu">
                Msigma Infotech PNRA 40 <br></br>Priyadarshini Nagar Pongumoodu
                <br></br>
                Thiruvananthapuram Kerala 695525
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={style.info}>
        <div className={style.legal}>
          <Link to="/">Terms & Conditions</Link>
          <Link to="/">Privacy Policy</Link>
        </div>
        <div className={style.copyright}>
          © 2024 Msigma Infotech Ltd. All rights reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;
