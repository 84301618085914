import React, { useState } from "react";
import PriceCard from "components/majik/PriceCard";
import MajikNew from "components/majik/MajikNew";
import { Typography, Button } from "@mui/material";
import CountdownTimer from "components/majik/CountDownTimer";

import style from "./Majik.module.css";

function Majik_() {
  const endDate = new Date("2024-02-18T23:59:59");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);

    setTimeout(() => {
      window.location.href = "https://rzp.io/l/PNHKaCk";
    }, 1000);
  };
  return (
    <div className={style.outer}>
      <div className={style.wrapper}>
        <MajikNew />
        <PriceCard />
      </div>
      <footer className={style.footer}>
        {" "}
        <Button onClick={handleSubmit}>
          <Typography
            textAlign="center"
            fontFamily="inter"
            color="black"
            fontWeight="700"
            sx={{ fontSize: { xs: "18px", sm: "20px" } }}
            width="auto"
          >
            {isLoading ? (
              "Redirecting..."
            ) : (
              <>
                Register for the demo session at
                <span style={{ margin: "0 5px" }}>
                  <span style={{ fontSize: "15px", margin: "0 5px" }}>
                    <strike>₹999</strike>
                  </span>
                  <span style={{ fontWeight: "800" }}>₹99</span>
                </span>
              </>
            )}
          </Typography>
        </Button>
        {/* <CountdownTimer endDate={endDate} /> */}
      </footer>
    </div>
  );
}

export default Majik_;
