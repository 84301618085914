import React from "react";
import PriceCard from "components/Majik/PriceCard";
import style from "./Majik.module.css";

function Majik() {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        {" "}
        <PriceCard />
      </div>
    </div>
  );
}

export default Majik;
