import { useState, useEffect } from "react";
import style from "./Enquiry.module.css";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
import SteinStore from "stein-js-client";
function EnquiryForm({ height, onSuccess }) {
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      isFullNameValid &&
      isEmailValid &&
      isPhoneValid &&
      selectedOption.trim() !== ""
    ) {
      setIsSubmitting(true);
      const SteinStore = require("stein-js-client");
      const store = new SteinStore(
        "https://api.steinhq.com/v1/storages/65a51e952acf6d63de7b880f"
      );

      const formData = {
        Name: fullName,
        Email: emailId,
        Phone: phone,
        selectedOption: selectedOption,
        timestamp: new Date().toLocaleString(),
      };

      try {
        // Append data to different sheets based on the selected option
        switch (selectedOption) {
          case "General":
            await store.append("General", [formData]);
            break;
          case "MAJIK":
            await store.append("Majik", [formData]);
            break;
          case "Corporate training":
            await store.append("CorporateTraining", [formData]);
            break;
          case "Offline training":
            await store.append("OfflineTraining", [formData]);
            break;
          default:
            console.error("Invalid selected option");
        }

        // Optional: Reset form fields after successful submission
        setFullName("");
        setEmailId("");
        setPhone("");
        setSelectedOption("");
        setIsFormSubmitted(true);
        onSuccess();
        // alert("Submitted Successfully");
        window.location.href = "https://rzp.io/l/PNHKaCk";
      } catch (error) {
        console.error("Error occurred while submitting the form:", error);
        alert("Error occurred while submitting the form. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Handle invalid form submission
      console.error(
        "Form validation failed. Please fill in all the required fields correctly."
      );
      alert("Please fill in all the required fields correctly.");
    }
  };

  // Email Validation
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailId(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
  };

  // Full name validation
  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);

    setIsFullNameValid(fullName.trim() !== "");
  };

  // Phone validation
  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setPhone(newPhone);

    const phoneRegex = /^\d{10}$/;
    setIsPhoneValid(phoneRegex.test(newPhone));
  };

  // Select field change
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div>
      <Card
        sx={{
          minWidth: 275,
          boxShadow: "none",
          backgroundColor: "#FFFCE8",
          padding: "37px",

          overflowY: "auto",
          "@media (max-width:600px)": {
            height: height,
          },
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography>FULL NAME</Typography>
              <TextField
                placeholder="John Doe"
                value={fullName}
                variant="standard"
                fullWidth
                onChange={handleFullNameChange}
                error={!isFullNameValid}
                helperText={!isFullNameValid ? "Full name cannot be empty" : ""}
                sx={{
                  justifyContent: "center",
                  padding: "10px",
                  maxWidth: "900px",
                  height: "60px",
                  backgroundColor: "white",
                  "& input": {
                    backgroundColor: "white",
                    caretColor: "white",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} marginTop="40px">
              <Typography>EMAIL ADDRESS</Typography>
              <TextField
                placeholder="johndoe@email.com"
                value={emailId}
                variant="standard"
                fullWidth
                onChange={handleEmailChange}
                error={!isEmailValid}
                helperText={!isEmailValid ? "check the format of email id" : ""}
                sx={{
                  justifyContent: "center",
                  padding: "10px",
                  maxWidth: "900px",
                  height: "60px",
                  backgroundColor: "white",
                  "& input": {
                    backgroundColor: "white",
                    caretColor: "white",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} marginTop="40px">
              <Typography>PHONE</Typography>
              <TextField
                placeholder="9876543210"
                value={phone}
                variant="standard"
                fullWidth
                type="number"
                onChange={handlePhoneChange}
                error={!isPhoneValid}
                helperText={
                  !isPhoneValid ? "Please enter correct mobile number" : ""
                }
                sx={{
                  justifyContent: "center",
                  padding: "10px",
                  maxWidth: "900px",
                  height: "60px",
                  backgroundColor: "white",
                  "& input": {
                    backgroundColor: "white",
                    caretColor: "white",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} marginTop="40px">
              <Typography>SELECT OPTION</Typography>
              <FormControl fullWidth>
                <Select
                  variant="standard"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  label="Select an Option"
                  sx={{
                    justifyContent: "center",
                    padding: "10px",
                    maxWidth: "900px",
                    height: "60px",
                    backgroundColor: "white",
                    "& input": {
                      backgroundColor: "white",
                      caretColor: "white",
                    },
                  }}
                >
                  <MenuItem value="General">General</MenuItem>
                  <MenuItem value="MAJIK">MAJIK</MenuItem>
                  <MenuItem value="Corporate training">
                    Corporate training
                  </MenuItem>
                  <MenuItem value="Offline training">Offline training</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Button
              className="formBTn"
              onClick={handleSubmit}
              style={{
                background: "black",
                marginTop: "15px",
                borderRadius: "50px",
                padding: "15px 30px 15px 30px",
              }}
              variant="contained"
            >
              {isSubmitting ? "Redirecting..." : "Pay Now"}
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default EnquiryForm;
