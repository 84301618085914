import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./routes";

import Preloader from "components-shared/Preloader/Preloader";
import About from "../pages/About";
import Projects from "../pages/Projects";
import Contact from "../pages/Contact";
import LnD from "../pages/LnD";
import Services from "../pages/Services";
import Career from "../pages/Career";
import Blogs from "../pages/Blogs";
import Home from "../pages/Home";
import Enquiry from "pages/Enquiry";
import Majik from "pages/Majik";
import Majik_ from "pages/Majik_";

// const Login = lazy(() => import("components/Login"));

export default function AppRouter() {
  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        <Route path="/" element={<Home />} />

        {/* <Route path={routes.Login} element={<Login />} /> */}
        <Route path={routes.About} element={<About />} />
        <Route path={routes.Projects} element={<Projects />} />
        <Route path={routes.Contact} element={<Contact />} />
        <Route path={routes.LnD} element={<LnD />} />
        <Route path={routes.Services} element={<Services />} />
        <Route path={routes.Career} element={<Career />} />
        <Route path={routes.Blogs} element={<Blogs />} />
        <Route path={routes.Enquiry} element={<Enquiry />} />
        <Route path={routes.Majik} element={<Majik />} />
        <Route path={routes.Majik_} element={<Majik_ />} />
      </Routes>
    </Suspense>
  );
}
