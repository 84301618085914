import Banner from "components/BlogPage/Banner/Banner";
import Cards from "components/BlogPage/Cards/Cards";
import React from "react";
import style from "./Blogs.module.css";

const Blogs = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Banner />
        <Cards />
      </div>
    </div>
  );
};

export default Blogs;
