import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import blog1 from "../../../assets/blog1.png";
import blog2 from "../../../assets/blog2.png";
import blog3 from "../../../assets/blog3.png";
import blog4 from "../../../assets/blog4.png";
import blog5 from "../../../assets/blog5.png";
import blog6 from "../../../assets/blog6.png";

const blogData = [
  {
    image: blog1,
    category: "Technology",
    title: "Lorem Ipsum has been the industry's standard dummy text ever",
    content:
      "It is a long established fact that a reader will be distracted...",
    date: "February 10, 2023",
  },
  {
    image: blog2,
    category: "Technology",
    title: "Lorem Ipsum has been the industry's standard dummy text ever",
    content:
      "It is a long established fact that a reader will be distracted...",
    date: "February 10, 2023",
  },
  {
    image: blog3,
    category: "Technology",
    title: "Lorem Ipsum has been the industry's standard dummy text ever",
    content:
      "It is a long established fact that a reader will be distracted...",
    date: "February 10, 2023",
  },
  {
    image: blog4,
    category: "Technology",
    title: "Lorem Ipsum has been the industry's standard dummy text ever",
    content:
      "It is a long established fact that a reader will be distracted...",
    date: "February 10, 2023",
  },
  {
    image: blog5,
    category: "Technology",
    title: "Lorem Ipsum has been the industry's standard dummy text ever",
    content:
      "It is a long established fact that a reader will be distracted...",
    date: "February 10, 2023",
  },
  {
    image: blog6,
    category: "Technology",
    title: "Lorem Ipsum has been the industry's standard dummy text ever",
    content:
      "It is a long established fact that a reader will be distracted...",
    date: "February 10, 2023",
  },
];

const Cards = () => {
  return (
    <Box sx={{ width: "100%", px: { xs: 3, md: 6 }, mt: 4 }}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        xs={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* Map over the blog data */}
        {blogData.map((blog, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={6}
            sx={{ width: "50%", mt: 2, px: 2 }}
          >
            <Box>
              <img
                style={{ objectFit: "cover", height: { xs: 24, md: 50 } }}
                src={blog.image}
                alt=""
                width="100%"
              />

              <Typography
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="#FFC700"
              >
                {blog.category}
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: 18, md: 34 },
                  mt: 1,
                  fontFamily: "inter",
                  fontWeight: "500",
                }}
              >
                {blog.title}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: 10, md: 13 },
                  mt: 1,
                  fontFamily: "inter",
                  fontWeight: "400",
                }}
              >
                {blog.content}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: 10, md: 14 },
                  mt: 1,
                  color: "#898989",
                  fontFamily: "inter",
                  fontWeight: "400",
                }}
              >
                {blog.date}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Cards;
