import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link } from "react-router-dom";
const Training = () => {
  return (
    <>
      <Typography
        fontFamily="Inter"
        fontSize="32px"
        fontWeight="300"
        textTransform="uppercase"
        borderBottom="1px solid #D9D9D9"
        paddingBottom="24px"
        marginTop="100px"
      >
        <span style={{ color: "#FFE000" }}>/ </span>corporate training
      </Typography>

      <Grid container spacing={3} marginTop="30px">
        <Grid item xs={12} md={6} sx={{ width: "50%", mt: 2, px: 2 }}>
          <Box>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ color: "#807e7c", lineHeight: 2 }}
            >
              We at Msigma Infotech, trains freshers for corporate companies
              helping you significantly reduce your training expenditures and
              time by leveraging our commitment to preparing students for your
              specific technological needs.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ mt: 2, color: "#807e7c", lineHeight: 2 }}
            >
              We offer your company a strategic advantage by providing skilled
              and trained professionals ready for immediate deployment. This
              approach not only saves significant costs in training and salaries
              but also accelerates the project delivery process, enhancing
              overall operational efficiency.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ width: "50%", mt: 2, px: 2 }}>
          <Box>
            <Typography variant="h6">Ensure Ready-to-Deploy Talent:</Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ mt: 2, color: "#807e7c", lineHeight: 2 }}
            >
              We train students in the exact technologies and skills required by
              your company. This specialized training means, you recieve
              candidates who are project-ready from day 1. Students from Msigma
              can be directly assigned to billable projects.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ mt: 2, color: "#807e7c", lineHeight: 2 }}
            >
              We offer your company a strategic advantage by providing skilled
              and trained professionals ready for immediate deployment. This
              approach not only saves significant costs in training and salaries
              but also accelerates the project delivery process, enhancing
              overall operational efficiency.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ px: { xs: 2, sm: 4, md: 6 }, mt: 4 }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ width: "100%", mt: 2, px: { xs: 1, sm: 2 } }}
        >
          <Box>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "600",
                backgroundColor: "#FFF6B3",
                padding: "8px",
                fontStyle: "italic",
              }}
            >
              What We Offer?
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Java based Training
              (Freshers/ Experienced)
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8 }} />
              Node.JS, React/Angular
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8 }} />
              Django, React/Angular
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8 }} />
              .Net Core Training
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8 }} /> React
              Native/Flutter Training
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Android/ IOS native
              Training
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8 }} /> UI/UX Training
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ width: "100%", mt: 2, px: { xs: 1, sm: 2 } }}
        >
          <Box>
            <Typography variant="h6">
              Access to Training Metrics and Standards:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ mt: 2, color: "#807e7c", lineHeight: 2 }}
            >
              We provide you detailed training and evaluation metrics before the
              placement interviews to ensure that the candidates meet your
              specific standards and expectations
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "600",
                backgroundColor: "#FFF6B3",
                padding: "8px",
                fontStyle: "italic",
                mt: 2,
              }}
            >
              Additional Benefits:
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <RemoveIcon sx={{ fontSize: 12 }} /> Tailored training programs
              based on your specific needs and technology requirements.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <RemoveIcon sx={{ fontSize: 12 }} /> Experienced trainers with
              industry-relevant knowledge and skills.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                mt: 2,
                lineHeight: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontStyle: "italic",
              }}
            >
              <RemoveIcon sx={{ fontSize: 12 }} /> Hands-on training with
              real-world project scenarios.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ px: { xs: 2, sm: 4, md: 6 }, mt: 4 }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: "100%",
            mt: 2,
            px: { xs: 1, sm: 2 },
            backgroundColor: "#FFFCE6",
          }}
        >
          <Box padding={{ xs: "20px", sm: "30px" }}>
            <Typography
              fontFamily="Inter"
              fontSize={{ xs: "24px", sm: "28px", md: "36px" }}
              fontWeight="400"
            >
              Corporate Training
            </Typography>

            <Box sx={{ px: { xs: 2, md: 4 } }}>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  fontWeight: "600",
                  fontStyle: "italic",
                  mt: 4,
                }}
              >
                Placement Based Training
              </Typography>

              <Typography
                alignItems=""
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "baseline",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Follow the
                Training Plan Discussed (Web Tech)
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                Perks
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />3 Months Course
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                Course break down
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Course duration
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Faculty
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Fee
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  mt: 2,
                  lineHeight: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontStyle: "italic",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: 8 }} /> Assessment
                criteria
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            width: "100%",
            mt: 2,
            px: { xs: 1, sm: 2 },
            backgroundColor: "#F7F7F7",
          }}
        >
          <Link to="/Majik">
            <Box padding={{ xs: "20px", sm: "30px" }}>
              <Typography
                fontFamily="Inter"
                fontSize={{ xs: "24px", sm: "28px", md: "36px" }}
                fontWeight="400"
              >
                Online Training
              </Typography>

              <Box sx={{ px: { xs: 2, md: 4 } }}>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontWeight: "600",
                    fontStyle: "italic",
                    mt: 4,
                  }}
                >
                  MAJIK (Mastering Java Interview Knack)
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    mt: 2,
                    lineHeight: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    fontStyle: "italic",
                  }}
                >
                  MAJIK helps you prepare for any JAVA interview out there, by
                  diving deep into core java and through assessments and
                  projects. Our 3 Level program containing:
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    mt: 2,
                    lineHeight: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    fontStyle: "italic",
                  }}
                >
                  <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                  MAJIK - LEVEL 1 - OOAD
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    mt: 2,
                    lineHeight: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    fontStyle: "italic",
                  }}
                >
                  <FiberManualRecordIcon sx={{ fontSize: 8 }} /> MAJIK - LEVEL 2
                  - INTERMEDIATE
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    mt: 2,
                    lineHeight: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    fontStyle: "italic",
                  }}
                >
                  <FiberManualRecordIcon sx={{ fontSize: 8 }} /> MAJIK - LEVEL 3
                  - ADVANCED
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    mt: 2,
                    lineHeight: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                  fontSize="14px"
                  fontWeight="600"
                  style={{
                    background: "#00000014",
                    width: "230px",
                    padding: "10px",
                    borderRadius: "12px",
                  }}
                >
                  Demo session for Just ₹99
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default Training;
