import React from "react";
import style from "./Career.module.css";

function Career() {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>Coming Soon</div>
    </div>
  );
}

export default Career;
