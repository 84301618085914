import React from "react";
import style from "./Projects.module.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

const predefinedColors = [
  "#F0F7EF",
  "#F7F0F8",
  "#E7F3FF",
  "#FFF3E1",
  // Add more predefined colors here as needed
];

const projectData = [
  {
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    backgroundColor: "#F0F7EF",
  },
  {
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    backgroundColor: "#F0F7EF",
  },
  {
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    backgroundColor: "#F0F7EF",
  },
  {
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    backgroundColor: "#F0F7EF",
  },
  {
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    backgroundColor: "#F0F7EF",
  },
  {
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    backgroundColor: "#F0F7EF",
  },
  {
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    backgroundColor: "#F0F7EF",
  },
  {
    title: "LMS - Learning Management System",
    description:
      "Our Learning Management System (LMS) is a comprehensive platform",
    backgroundColor: "#F0F7EF",
  },
];

function Projects() {
  const getColor = (index) => {
    return predefinedColors[index % predefinedColors.length];
  };
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Grid container marginTop="20px" spacing={3}>
          <Grid item md={7} xs={12}>
            <Typography
              fontFamily="Inter"
              fontSize="15px"
              color="#FFC700"
              marginBottom="25px"
            >
              Projects
            </Typography>
            <Typography
              fontFamily="Inter"
              fontSize="50px"
              fontWeight="500"
              maxWidth="568px"
              textAlign="left"
            >
              Lorem Ipsum is not simply random text
            </Typography>
          </Grid>
          <Grid item md={5} xs={12} marginTop="60px">
            <Typography
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              maxWidth="366px"
              textAlign="left"
            >
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} marginTop="44px">
          {projectData.map((project, index) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
              <Card
                sx={{
                  maxWidth: "397px",
                  height: "347px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                style={{ backgroundColor: getColor(index) }}
              >
                <CardContent sx={{ p: 3.5 }}>
                  <Typography
                    fontFamily="Inter"
                    fontSize="21px"
                    fontWeight="500"
                  >
                    {project.title}
                  </Typography>
                  <Typography
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    marginTop="20px"
                  >
                    {project.description}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    px: 4,
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    size="small"
                    sx={{ textTransform: "none", mb: 2 }}
                    style={{ color: "#000000" }}
                  >
                    <Typography
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      marginTop="20px"
                      borderBottom="1px solid black"
                    >
                      Learn More
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default Projects;
