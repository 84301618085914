import React from "react";
import style from "./Service.module.css";
import Cards from "../../components/Service/serviceCards/Cards";
function Services() {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Cards />
      </div>
    </div>
  );
}

export default Services;
